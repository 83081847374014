<template>
	<div class="body">
		<div class="search">
			<Form inline @submit.native.prevent>
				<FormItem :label-width="10">
					<Button type="primary" icon="md-add" @click="edit_shows()">创建短信</Button>
				</FormItem>
			</Form>
		</div>
		<div class="table">
			<Table :data="data" :columns="columns" :loading="table_loading">
				<template slot-scope="{row}" slot="type">
					<div>{{ type_array[row.type].value }}</div>
				</template>
				<template slot-scope="{row}" slot="operation">
					<a @click="edit_shows(row.id)" style="margin-right:10px;">编辑</a>
					<a @click="list_del(row.id)">删除</a>
				</template>
			</Table>
		</div>
		<Modal v-model="edit_show">
			<Form :model="RuleForm" inline @submit.native.prevent label-colon label-position="right" :label-width="120"
				:rules="rules" ref="ruleform">
				<FormItem label="选择签名" prop="sign_id">
					<Select v-model="RuleForm.sign_id" style="width: 300px;">
						<Option v-for="(item,index) in sign_array" :key="index" :value="item.sign_id">
							{{ item.sign_name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="选择模板" prop="template_id">
					<Select v-model="RuleForm.template_id" style="width: 300px;">
						<Option v-for="(item,index) in template_array" :key="index" :value="item.template_id">
							{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="选择消息用途" prop="type">
					<Select v-model="RuleForm.type" style="width: 300px;">
						<Option v-for="(item,index) in type_array" :key="index" :value="item.type">{{ item.value }}</Option>
					</Select>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="edit_save()">确定</Button>
				<Button @click="edit_show=false">取消</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				//表格加载状态
				table_loading: false,
				//显示对话框
				edit_show: false,
				//消息类型
				type_array: [{
					value: '登录',
					type: 0
				},{
					value: '余额不足',
					type: 1
				},{
					value: '商户号切换',
					type: 2
				}],
				//修改ID
				id: null,
				//提交数据
				RuleForm: {
					template_id: null,
					sign_id: null,
					type: null,
				},
				//签名列表
				sign_array: [{
					sign_name: '寻疗健康',
					sign_id: 1
				}, {
					sign_name: '九零科技',
					sign_id: 2
				}],
				//模板列表
				template_array: [{
					name: '登录啊',
					template_id: 1
				}, {
					name: '注册啊',
					template_id: 2
				}],
				//数据
				data: [{
					id: 1,
					name: '登录啊',
					content: '您的验证是{1}，请不要告诉别人，五分钟内有效~',
					type: 0,
					sign_name: '寻疗健康',
					create_time: '2021-9-15 14:38:58',
					user_number: 20,
				}],
				//表头
				columns: [{
					title: 'ID',
					key: 'id',
					minWidth: 100,
					align: 'center'
				}, {
					title: '模板名称',
					key: 'name',
					minWidth: 150,
					align: 'center'
				}, {
					title: '模板内容',
					key: 'content',
					minWidth: 300,
					align: 'center'
				}, {
					title: '短信类型',
					slot: 'type',
					minWidth: 150,
					align: 'center'
				}, {
					title: '短信签名',
					key: 'sign_name',
					minWidth: 200,
					align: 'center'
				}, {
					title: '创建时间',
					key: 'create_time',
					minWidth: 200,
					align: 'center',
				}, {
					title: '发送用户数量',
					key: 'user_number',
					minWidth: 150,
					align: 'center'
				}, {
					title: '操作',
					slot: 'operation',
					minWidth: 200,
					align: 'center'
				}],
				//验证规则
				rules: {
					sign_id: [{
						required: true,
						message: '请选择短信签名',
						trigger: 'change',
						type: 'number'
					}],
					template_id: [{
						required: true,
						message: '请选择短信模板',
						trigger: 'change',
						type: 'number'
					}],
					type: [{
						required: true,
						message: '请选择短信用途',
						trigger: 'change',
						type: 'number'
					}]
				}
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				var _this = this
				this.table_loading = true
				this.requestApi('/adm/get_message_list', {}).then(
					(res) => {
						if(res.code == 200) {
							_this.data = res.data
							_this.table_loading = false
						}
					})
			},
			edit_shows(id = null) {
				var _this = this

				this.id = id

				this.requestApi('/adm/get_edit_list', {
					id
				}).then(
					(res) => {
						if (res.code == 200) {
							_this.template_array = res.data.template_array
							_this.sign_array = res.data.sign_array
							_this.type_array = res.data.type_array
							if (res.data.RuleForm.length != 0) {
								_this.RuleForm = res.data.RuleForm
							}
							this.edit_show = true
						} else {
							_this.$Message.error(res.msg)
							_this.init()
						}
					}
				)
			},
			edit_save() {
				var _this = this

				let id = this.id

				let ruleform = this.RuleForm
				this.$refs.ruleform.validate(function(valid) {
					console.log(valid)
					if (valid) {
						_this.requestApi('/adm/edit_save_list', {
							ruleform,
							id
						}).then((res) => {
							if (res.code == 200) {
								_this.$Message.success(res.msg)
								_this.edit_show = false
								_this.$refs.ruleform.resetFields()
								_this.RuleForm = {
									template_id: 0,
									sign_id: 0,
									type: 0,
								}
								_this.init()
							} else {
								_this.$Message.error(res.msg)
							}
						})
					}
				})
			},
			list_del(id) {
				var _this = this
				this.$Modal.confirm({
					title: '删除短信',
					content: '确定删除该短信吗？',
					onOk: () => {
						this.requestApi('/adm/del_sms_list', {
							id
						}).then(
							(res) => {
								if (res.code == 200) {
									_this.$Message.success(res.msg)
								} else {
									_this.$Message.error(res.msg)
								}
								_this.init()
							}
						)
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.body {
		padding: 16px;
		background-color: #fff;
	}

	.search {
		background-color: rgb(248, 248, 249);
		padding: 5px;
		margin-bottom: 10px;
	}

	.success {
		background-color: rgb(60, 200, 130);
		padding: 10px;
		color: #fff;
		border-radius: 12px;
	}
</style>
